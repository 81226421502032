exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-people-js": () => import("./../../../src/templates/people.js" /* webpackChunkName: "component---src-templates-people-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-post-tags-js": () => import("./../../../src/templates/post-tags.js" /* webpackChunkName: "component---src-templates-post-tags-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "slice---src-components-global-footer-footer-js": () => import("./../../../src/components/Global/Footer/Footer.js" /* webpackChunkName: "slice---src-components-global-footer-footer-js" */),
  "slice---src-components-header-js": () => import("./../../../src/components/Header.js" /* webpackChunkName: "slice---src-components-header-js" */)
}

